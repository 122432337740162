import React from 'react'
import Thankyou from '../../components/cards/payment/thank-you';
import { LiveChatWidget } from '@livechat/widget-react'

const PaymentThanks = () => {
  return (
    <div>
        <Thankyou />
        {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </div>
  )
}

export default PaymentThanks;