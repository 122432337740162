import { navigate } from "gatsby";
import React from "react";
import "./style.scss";

const Thankyou = () => {

  const navigateBack = () => {
    navigate("/products/collections");
  };

  return (
    <div>
      <div className="thank-you">
        <div className="card">
          <div className="header">
            <img src="/images/common/plus_new_logo.svg" />
          </div>
          <div className="thank-you-body">
            <div className="done"> Done!</div>
            <span>
              Your production order has been placed <br />
              and the invoice has been emailed.
            </span>
            <span>
              <button className="button-col" onClick={navigateBack}>Back to products</button>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
